import '@fontsource/overpass';
import '@fontsource/overpass/300.css';
import '@fontsource/overpass/500.css';
import '@fontsource/rasa';

import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    specialHeader: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    specialHeader?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    specialHeader: true;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['overpass', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    specialHeader: {
      fontSize: '2.25rem',
      lineHeight: 1,
      fontFamily: 'rasa ,Helvetica,Arial,sans-serif',
    },
  },
  palette: {
    primary: {
      main: '#f9a034',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#414042',
    },
  },
});
