import { Box } from '@mui/material';

import { SignInPage } from './pages';

export function App() {
  return (
    <Box sx={{ my: 4 }}>
      <SignInPage />
    </Box>
  );
}
